body{
  box-sizing: border-box;
  
}
/* navbar */
.video-container {
  display: grid;
  place-items: center; /* Align both horizontally and vertically */
  position: relative;
  width: 100%;
  height: auto; /* Adjust as needed */
  /* background-color: #004af5b7; */
  padding-top: 20px;
  padding-bottom: 40px;
  
}
.borderVideo{
  border: 3px solid white;
  background-color: #f7f8fa;
  padding: 30px;
  margin: 30px;
}
.roundedd{
  --g: 4px;     /* the gap */
  --b: 5px;    /* border thickness*/
  --c: #f2f6f7; /* the color */
  
  
  padding: calc(var(--g) + var(--b));
  --_c: #0000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg ,var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg,var(--_c)) repeat-y;
  background:
    var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
    var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%);
  background-size: 200% var(--b),var(--b) 200%;
  cursor: pointer;
  filter: grayscale(50%);
  transition: .3s;
}
.roundedd:hover{
  --_p: 75%;
  filter: grayscale(0%);
}
.video-container video {
  max-width: 60%;
  max-height: 100%;
  padding: 30px;
}


/* versions home page */



h1 {
  font-size: 22px;
  color: #333;
  text-align: center;
  margin-bottom: 1.4rem;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.media {
  max-width: 300px;
  max-height: 200px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media img {
  max-width: 100%;
  height: auto;
}

.layer {
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 90%;
  background: #fff;
  color: #151e3f;
  transition: all 0.9s ease;
  p {
    transition: all 0.9s ease;
    transform: scale(0.1);
  }
}

p {
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px;
}

.media:hover .layer {
  opacity: 0.8;
  width: 90%;
  transition: all 0.5s ease;
  p {
    transform: scale(1);
    transition: all 0.9s ease;
  }
}
/* configure */
.gallery-box-container {
  display: flex;
  justify-content: space-between;
  flex: 0 1 90%;
  flex-wrap: wrap;
}

.gallery-box {
  display: block;
  color: #FFF;
  flex: 0 1 calc(25% - 15px);
  height: 400px;
  background: #0087d5;
  border-radius: 10px;
  box-shadow: 3px 3px 15px rgba(0,0,0,0.3);
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .gallery-box {
    flex: 0 1 calc(50% - 15px);
    margin: 15px 0;
  }
}

@media screen and (max-width: 640px) {
  .gallery-box {
    flex: 0 1 calc(100% - 15px);
  }
}

.gallery-box__img-container {
  display: block;
  width: 140%;
  height: 350px;
  background: #FFF;
  border-radius: 0 0 40px 40px;
  border: 2px solid #FFFFF8;
  transform: rotate(25deg);
  position: absolute;
  left: -15%;
  top: -25px;
  transition: all 0.4s ease;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .gallery-box__img-container {
    height: 420px;
    top: -180px;
  }
}

@media screen and (max-width: 900px) {
  .gallery-box__img-container {
    height: 420px;
    top: -120px;
  }
}

@media screen and (max-width: 640px) {
  .gallery-box__img-container {
    height: 450px;
    top: -190px;
  }
}

.gallery-box:hover .gallery-box__img-container {
  width: 160%;
  height: 450px;
  border-radius: 0;
  transform: rotate(0);
  left: -2px;
  top: -120px;
}

.gallery-box__img {
  display: block;
  width: 100%;
  transform: rotate(-25deg) scale(1.1);
  transition: all 0.4s ease;
}

.gallery-box:hover .gallery-box__img {
  transform: rotate(0deg) scale(1);
}

.gallery-box__text-wrapper {
  transition: all 0.4s ease;
  position: absolute;
  left: 15px;
  top: 250px;
  width: 80%;
}

.gallery-box:hover .gallery-box__text-wrapper {
  top: 288px;
}

.gallery-box__text {
  font-weight: 500;
  font-size: 22px;
  padding: 8px 0;
  box-shadow: 8px 0 0 rgba(0,0,0,0.7), -8px 0 0 rgba(0,0,0,0.7);
  box-decoration-break: clone;
  line-height: 2;
  background: rgba(0,0,0,0.7);
}

@media screen and (max-width: 900px) {
  .gallery-box__text {
    font-size: 20px;
  }
}

/* build */

/* footer */
.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
.logoFooter {
  width: 200px; /* Définition de la largeur par défaut */
  transition: width 0.3s ease; /* Transition douce pour une animation fluide */
}

.logoFooter:hover {
  width: 300px; /* Changement de largeur au survol */
}
.rounded{
  --g: 4px;     /* the gap */
  --b: 5px;    /* border thickness*/
  --c: #0073f5; /* the color */
  
  
  padding: calc(var(--g) + var(--b));
  --_c: #0000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg ,var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg,var(--_c)) repeat-y;
  background:
    var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
    var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%);
  background-size: 200% var(--b),var(--b) 200%;
  cursor: pointer;
  filter: grayscale(50%);
  transition: .3s;
}
.rounded:hover{
  --_p: 75%;
  filter: grayscale(0%);
}
.colorBackGround{
  background-color: #004af5b7 !important;
}
.carousel-caption {
  top: 5%;
  height: auto; /* Hauteur dynamique */
  width: 50%;
  left: 5%;
  background-color: rgba(0, 0, 0, 0.454);
  padding: 10px;
  border-radius: 30px;
}
.alpine{
  background-color: rgba(0, 0, 0, 0.454);
}
.textWhite {
  color: white;
  padding: 10px;
}

/* Media Query pour les petits écrans */
@media screen and (max-width: 576px) {
  .carousel-caption {
    height: auto; /* Hauteur dynamique pour les petits écrans */
  }
}

/* Media Query pour les écrans moyens */
@media screen and (min-width: 576px) and (max-width: 992px) {
  .carousel-caption {
    height: 60%; /* Hauteur dynamique pour les écrans moyens */
  }
}

/* Media Query pour les grands écrans */
@media screen and (min-width: 992px) {
  .carousel-caption {
    height: 50%; /* Hauteur dynamique pour les grands écrans */
  }
}
/* Media Query pour les grands écrans */
@media screen and (min-width: 1024px) {
  .carousel-caption {
    height: 50%; /* Hauteur dynamique pour les grands écrans */
  }
}
/* Media Query pour les grands écrans */
@media screen and (min-width: 1440px) {
  .carousel-caption {
    height: 35%; /* Hauteur dynamique pour les grands écrans */
  }
}
/* Media Query pour les grands écrans */
@media screen and (min-width: 1600px) {
  .carousel-caption {
    height: 25%; /* Hauteur dynamique pour les grands écrans */
  }
}




.shadow-blue {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.945); /* Remplacez rgba(0, 0, 255, 0.5) par la couleur d'ombre bleue de votre choix */
}